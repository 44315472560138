import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import styles from "./top-header.module.css";
import Link from "next/link";
import dynamic from "next/dynamic";
import { useCookies } from "react-cookie";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { signOut } from "next-auth/client";
import { logoutUser } from "../../lib/magento-api"
import FormLoader from "../ui/FormLoader"
import Cookies from 'universal-cookie';
import { setReduxSignout } from "../../lib/sessionHandler"
import { HamburgerOptionClick, TopNavigationClick } from "/lib/ga";
import { EventCommonObj } from "../../lib/services/helpers/eventCommonObject";
import logo from '~/public/images/kama-logo-grey.png';
import cancel from '~/public/images/grey-cross.svg';
import house from '~/public/images/house-icon-grey.svg';
import youtube from '~/public/images/youtube_upd.inline.svg';
import facebook from '~/public/images/fb_upd.inline.svg';
import twitter from '~/public/images/x_upd.inline.svg';
import insta from '~/public/images/insta_upd.inline.svg';
import hamburger_icon from '~/public/images/hamburger-icon.inline.svg';
import { useIsMobile } from "/lib/hooks/useIsMobile";

const NewsletterModal = dynamic(() => import("../ui/NewsletterModal"));

const useStyles = makeStyles({
  list: {
    width: 280,
  },
  fullList: {
    width: "auto",
  },
});

export default function ToggleMenu(menudata) {
  const isMobileDevice = useIsMobile();
  const categoryUrlSuffix = ".html";
  const classes = useStyles();
  const cookiesStore = new Cookies();
  const [showLoader, setShowLoader] = useState(false);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [sessionData, setSessionData] = useState([]);
  const [showCurrencyModal, setShowCurrencyModal] = useState(false);
  const [cookie, setCookie] = useCookies(["store"]);
  const websiteurl =
    typeof cookie.store == "undefined" || cookie.store == "default"
      ? ""
      : "/international";

  const eventCommonObj = EventCommonObj(sessionData, cookie)

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift" || event.key === "Control")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    setSessionData(menudata?.session)

  }, [menudata?.session]);

  const handleChange = (e) => {
    HamburgerOptionClick('N/A', 'N/A', 'hamburger_currency_change', 'N/A', eventCommonObj)
    let webValue = e.target.value
    signOut({ redirect: false })
    cookiesStore.set('store', webValue, { maxAge: 30 * 24 * 60 * 60, path: '/', sameSite: true });
    const response = async () => {
      try {
        await logoutUser(webValue);
        setReduxSignout()
        cookiesStore.set('wishlist_items', '', { maxAge: -1, expires: new Date(Date.now() - 12592000), path: "/" });
        cookiesStore.set('wishlist_sku', '', { maxAge: -1, expires: new Date(Date.now() - 12592000), path: "/" });
        cookiesStore.set('wishlist_parent_sku', '', { maxAge: -1, expires: new Date(Date.now() - 12592000), path: "/" });
        let url = webValue == "default" ? process.env.NEXTAUTH_URL : process.env.NEXTAUTH_URL + "/international/";
        if ((typeof window !== 'undefined')) {
          if (process.browser) {
            // router.push(url)
            window.location.href = url
          }
        }
      } catch (error) {
        console.log('error', error);
      }

    };
    response();
  };

  function handleShowCurrencyModal() {
    setShowCurrencyModal(false)
    setCookie("currency_switch", 1, {
      path: "/",
      maxAge: 12 * 60 * 60,
      sameSite: true,
    });
  }
  let storeType = typeof cookie.store == "undefined" || cookie.store == "default" ? "default" : "international";

  function SignoutUser(cookie) {
    signOut({ redirect: false })
    const response = async () => {
      try {
        await logoutUser();
        setReduxSignout()
        cookiesStore.set('wishlist_items', '', { maxAge: -1, expires: new Date(Date.now() - 12592000), path: "/" });
        cookiesStore.set('wishlist_sku', '', { maxAge: -1, expires: new Date(Date.now() - 12592000), path: "/" });
        cookiesStore.set('wishlist_parent_sku', '', { maxAge: -1, expires: new Date(Date.now() - 12592000), path: "/" });
        cookiesStore.set('mobileVerified', '', { maxAge: -1, expires: new Date(Date.now() - 12592000), path: "/" });
        cookiesStore.set('mobileverify', '', { maxAge: -1, expires: new Date(Date.now() - 12592000), path: "/" });
        let url = typeof cookie.store == "undefined" || cookie.store == "default" ? process.env.NEXTAUTH_URL : process.env.NEXTAUTH_URL + "/international/";
        if ((typeof window !== 'undefined')) {
          if (process.browser) {
            //  router.push(url)
            window.location.href = url
          }
        }
      } catch (error) {
        console.log('error', error);
      }
    };
    response();
  }

  function changeUserCurrecnyViaPrompt(newVal) {

    setShowLoader(true)
    signOut({ redirect: false })
    cookiesStore.set('store', newVal, { maxAge: 30 * 24 * 60 * 60, path: '/', sameSite: true });
    const response = async () => {
      try {
        await logoutUser(newVal, true);
        setShowLoader(false)
        setShowCurrencyModal(false)
        let url = newVal == "default" ? process.env.NEXTAUTH_URL : process.env.NEXTAUTH_URL + "/international/";
        if ((typeof window !== 'undefined')) {
          if (process.browser) {
            window.location.href = url
          }
        }
      } catch (error) {
        console.log('error', error);
        setShowLoader(false)
      }
    };
    response();
  }

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <div className="hamburger_menu">
        <div className={styles.hamburger_hrd}>
          <div className={styles.hamburger_logo}>
            <Link
              href={(websiteurl != '') ? websiteurl : "/"}
              className="active"
              onClick={() => HamburgerOptionClick('N/A', 'N/A', 'hamburger_logo', 'N/A', eventCommonObj)}>

              <img
                loading="lazy"
                src={logo.src}
                title="kama Ayurveda Logo"
                alt="kama Ayurveda Logo"
                width="100px"
                height="27px"
              />

            </Link>
          </div>
          <div className={styles.hamburger_close}>
            <ul>
              {/* temporary disable usd store */}
              {/* 
              <li key="store" className={styles.inr_desk}>
                <select id="lang" onChange={handleChange} value={cookie.store}>
                  <option value="default">₹ INR</option>
                  <option value="international">$ USD</option>
                </select>
              </li> */}
              <li key="closeicon" className={styles.mobile_close}>
                <img
                  loading="lazy"
                  onClick={(e) => {
                    HamburgerOptionClick('N/A', 'N/A', 'hamburger_close', 'N/A', eventCommonObj);
                    toggleDrawer(anchor, false)(e);
                  }
                  }
                  src={cancel.src}
                  className="d-block"
                  alt="close"
                  width="20px"
                  height="20px"
                />
              </li>
            </ul>
          </div>
        </div>
        <div onKeyDown={toggleDrawer(anchor, false)}>
          {sessionData && sessionData.user ? (
            <div className={styles.hamburger_menu11}>
              <Link
                prefetch={false}
                href={`${websiteurl}/customer/account`}
                onClick={(e) => {
                  HamburgerOptionClick('N/A', 'N/A', `Hi_${sessionData.user.firstname}`, 'N/A', eventCommonObj)
                  toggleDrawer(anchor, false)(e)
                }
                }>
                Hi{sessionData.user.firstname}

              </Link>
              <div className={styles.hamburger_sing}>
                {" "}

                {sessionData && (
                  <>
                    <button
                      onClick={() => {
                        HamburgerOptionClick('N/A', 'N/A', 'sign_out_from_hamburger', 'N/A', eventCommonObj)
                        SignoutUser(cookie)
                      }}
                    >
                      Sign out
                    </button>
                  </>
                )}
              </div>
            </div>
          ) : (
            <div className={styles.hamburger_menu}>
              <ul>
                <li>
                  <Link
                    prefetch={false}
                    href={`${websiteurl}/customer/account/login`}
                    onClick={(e) => {
                      HamburgerOptionClick('N/A', 'N/A', 'sign_in_from_hamburger', 'N/A', eventCommonObj)
                      toggleDrawer(anchor, false)(e)
                    }
                    }>
                    Sign in
                  </Link>
                </li>
                <li>
                  <Link
                    prefetch={false}
                    href={`${websiteurl}/customer/account/create`}
                    onClick={(e) => {
                      HamburgerOptionClick('N/A', 'N/A', 'register_from_hamburger', 'N/A', eventCommonObj)
                      toggleDrawer(anchor, false)(e)
                    }
                    }>
                    Register
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </div>
        <div
          onKeyDown={toggleDrawer(anchor, false)}
          className={styles.hamburger_menu1}
        >
          {menudata?.menudata?.length > 0 && (
            <ul>
              <li onClick={(e) => {
                HamburgerOptionClick('N/A', 'N/A', 'home_hamburger', 'N/A', eventCommonObj)
                toggleDrawer(anchor, false)(e)
              }
              }><Link href={(websiteurl != '') ? websiteurl : "/"} className="active">
                  Home<span className={styles.house_icon}><img loading="lazy" src={house.src} width={18} height={16} alt="house icon" /></span>

                </Link></li>
              {menudata?.menudata.map((category) =>
                category.children?.length > 0 ? (
                  <li className={`level_${category.level}`} key={category.id}>
                    <Accordion className={styles.menu_accordion}>
                      <AccordionSummary
                        className={styles.menu_accordion_summary}
                        expandIcon=" "
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="additional-actions1-header"
                      >
                        <Link
                          prefetch={false}
                          href={{
                            pathname: "/_url-resolver",
                            query: {
                              pathname: `/${category.url_path + categoryUrlSuffix
                                }`,
                              type: "CATEGORY",
                              url_path: category.url_path,
                              url_key: category.url_key,
                              cat_id: category.id,
                            },
                          }}
                          as={`${websiteurl}/${category.url_path + categoryUrlSuffix}`}
                          onClick={(e) => {
                            HamburgerOptionClick(`${category.name}`, 'N/A', 'N/A', 'N/A', eventCommonObj)
                            toggleDrawer(anchor, false)(e)
                          }
                          }>

                          {category.name}{" "}
                          {category && category.category_tag ? (
                            <span className={styles.new}>
                              {category.category_tag}
                            </span>
                          ) : (
                            ""
                          )}

                        </Link>
                      </AccordionSummary>
                      <ul>
                        {category.children.sort((a, b) => a.position - b.position)
                          .slice(0)
                          .map((subcategory, index) => (
                            <AccordionDetails
                              className={styles.menu_accordion_detail}
                              key={"Toggle" + subcategory.id}
                            >
                              <li
                                className={`level_${subcategory.level}`}
                                key={subcategory.id}
                              >
                                <Link
                                  prefetch={false}
                                  href={{
                                    pathname: "/_url-resolver",
                                    query: {
                                      pathname: `/${subcategory.url_path + categoryUrlSuffix
                                        }`,
                                      type: "CATEGORY",
                                      url_path: subcategory.url_path,
                                      url_key: subcategory.url_key,
                                      cat_id: subcategory.id,
                                    },
                                  }}
                                  as={`${websiteurl}/${(subcategory.url_path.includes('haircare/') ?
                                    subcategory.url_path.replace(/haircare/g, "hair")
                                    : (subcategory.url_path.includes('skincare/') ?
                                      subcategory.url_path.replace(/skincare/g, "skin") :
                                      subcategory.url_path)) + categoryUrlSuffix
                                    }`}
                                  onClick={(e) => {
                                    HamburgerOptionClick(`${category.name}`, `${subcategory.name}`, 'N/A', 'N/A', eventCommonObj)
                                    toggleDrawer(anchor, false)(e)
                                  }
                                  }>

                                  {subcategory.name}

                                </Link>
                              </li>
                              {subcategory?.children?.map((subcategoryLevel2) => (
                                subcategoryLevel2.url_path.includes('corporate-gifting') ?
                                  <li key={subcategoryLevel2.id}>
                                    <Link
                                      prefetch={false}
                                      href={`${websiteurl}/corporate-gifting`}
                                      onClick={(e) => {
                                        HamburgerOptionClick(`${category.name}`, `${subcategory.name}`, `${subcategoryLevel2.name}`, 'N/A', eventCommonObj)
                                        toggleDrawer(anchor, false)(e)
                                      }
                                      }>

                                      {subcategoryLevel2.name}{" "}
                                      {subcategoryLevel2 && subcategoryLevel2.category_tag ? (
                                        <span className={styles.new}>
                                          {subcategoryLevel2.category_tag}
                                        </span>
                                      ) : (
                                        ""
                                      )}

                                    </Link>
                                  </li> : subcategoryLevel2.url_path.includes('e-gift-card') ?
                                    <li key={subcategoryLevel2.id}>
                                      <Link
                                        prefetch={false}
                                        href={`${websiteurl}/gifting/e-gift-card`}
                                        onClick={(e) => {
                                          HamburgerOptionClick(`${category.name}`, `${subcategory.name}`, `${subcategoryLevel2.name}`, 'N/A', eventCommonObj)
                                          toggleDrawer(anchor, false)(e)
                                        }
                                        }>

                                        {subcategoryLevel2.name}{" "}
                                        {subcategoryLevel2 && subcategoryLevel2.category_tag ? (
                                          <span className={styles.new}>
                                            {subcategoryLevel2.category_tag}
                                          </span>
                                        ) : (
                                          ""
                                        )}

                                      </Link>
                                    </li>
                                    :
                                    <li
                                      className={`level_${subcategoryLevel2.level}`}
                                      key={subcategoryLevel2.id}
                                    >
                                      <Link
                                        prefetch={false}
                                        href={{
                                          pathname: "/_url-resolver",
                                          query: {
                                            pathname: `/${subcategoryLevel2.url_path +
                                              categoryUrlSuffix
                                              }`,
                                            type: "CATEGORY",
                                            url_path: subcategoryLevel2.url_path,
                                            url_key: subcategoryLevel2.url_key,
                                            cat_id: subcategoryLevel2.id,
                                          },
                                        }}
                                        as={`${websiteurl}/${(subcategoryLevel2.url_path.includes('haircare/') ?
                                          subcategoryLevel2.url_path.replace(/haircare/g, "hair")
                                          : (subcategoryLevel2.url_path.includes('skincare/') ?
                                            subcategoryLevel2.url_path.replace(/skincare/g, "skin") :
                                            subcategoryLevel2.url_path)) +
                                          categoryUrlSuffix
                                          }`}
                                        onClick={(e) => {
                                          HamburgerOptionClick(`${category.name}`, `${subcategory.name}`, `${subcategoryLevel2.name}`, '', eventCommonObj)
                                          toggleDrawer(anchor, false)(e)
                                        }
                                        }>

                                        {subcategoryLevel2.name}

                                      </Link>
                                    </li>
                              ))}
                            </AccordionDetails>
                          ))}
                      </ul>
                    </Accordion>
                  </li>
                ) : category.url_path == "free-trial" ? (
                  <li key={category.id}>
                    <Link
                      prefetch={false}
                      href={`${websiteurl}/free-trial`}
                      onClick={toggleDrawer(anchor, false)}>

                      {category.name}{" "}
                      {category && category.category_tag ? (
                        <span className={styles.new}>
                          {category.category_tag}
                        </span>
                      ) : (
                        ""
                      )}

                    </Link>
                  </li>
                ) : (
                  <li key={category.id}>
                    <Link
                      prefetch={false}
                      href={{
                        pathname: "/_url-resolver",
                        query: {
                          pathname: `/${category.url_path + categoryUrlSuffix}`,
                          type: "CATEGORY",
                          url_path: category.url_path,
                          url_key: category.url_key,
                          cat_id: category.id,
                        },
                      }}
                      as={`${websiteurl}/${category.url_path + categoryUrlSuffix
                        }`}
                      onClick={toggleDrawer(anchor, false)}>

                      {category.name}{" "}
                      {category && category.category_tag ? (
                        <span className={styles.new}>
                          {category.category_tag}
                        </span>
                      ) : (
                        ""
                      )}

                    </Link>
                  </li>
                )
              )}
              {/* { storeType == 'default' && <li><Link href={websiteurl + "/free-trial"}><a onClick={toggleDrawer(anchor, false)}>Free trial</a></Link></li> } */}
              <li onClick={(e) => {
                HamburgerOptionClick(`N/A`, `N/A`, `All Products`, 'N/A', eventCommonObj)
                toggleDrawer(anchor, false)(e)
              }}>
                <Link href={websiteurl + "/shop.html"}>All Products</Link>
              </li>
              {menudata.staticmenu.map((page) => (
                <li key={page.id}>
                  <Accordion className={styles.menu_accordion}>
                    <AccordionSummary
                      className={styles.menu_accordion_summary}
                      expandIcon=" "
                      aria-label="Expand"
                      aria-controls="additional-actions1-content"
                      id={"Toggle" + page.id}
                    >

                      <a onClick={() => HamburgerOptionClick(`N/A`, `N/A`, `${page.name}`, 'N/A', eventCommonObj)
                      }>{page.name}</a>

                    </AccordionSummary>
                    <ul>
                      {page.children.map((subcategory) => (
                        <AccordionDetails
                          className={styles.menu_accordion_detail}
                          key={`Toggle${subcategory.id}`}
                        >
                          <li key={subcategory.id}>
                            <Link
                              prefetch={false}
                              href={subcategory.url_path}
                              as={`${websiteurl}${subcategory.url_path}`}
                              onClick={(e) => {
                                HamburgerOptionClick(`${page.name}`, 'N/A', `${subcategory.name}`, 'N/A', eventCommonObj);
                                toggleDrawer(anchor, false)(e);
                              }}>

                              {subcategory.name}

                            </Link>
                          </li>
                        </AccordionDetails>
                      ))}

                    </ul>
                  </Accordion>
                </li>
              ))}
              <li onClick={(e) => {
                HamburgerOptionClick(`N/A`, `N/A`, `Wishlist`, 'N/A', eventCommonObj)
                toggleDrawer(anchor, false)(e)
              }}
              >
                <Link prefetch={false} href={`${websiteurl}/wishlist`}>Wishlist</Link>
              </li>

              {/* <li onClick={(e) => {
                HamburgerOptionClick(`N/A`, `N/A`, `Corporate_Gifting`, 'N/A', eventCommonObj)
                toggleDrawer(anchor, false)(e)
              }}
              >
                <Link prefetch={false} href={`${websiteurl}/corporate-gifting`}>Corporate Gifting</Link>
              </li> */}
              {storeType != "international" &&
                <li onClick={(e) => {
                  HamburgerOptionClick(`N/A`, `N/A`, `Amaaya`, 'N/A', eventCommonObj)
                  toggleDrawer(anchor, false)(e)
                }}
                >
                  <Link prefetch={false} href={`${websiteurl}/amaaya`}>Loyalty Program</Link>
                </li>
              }

              <li onClick={(e) => {
                HamburgerOptionClick(`N/A`, `N/A`, `Offers`, 'N/A', eventCommonObj)
                toggleDrawer(anchor, false)(e)
              }}
              >
                <Link prefetch={false} href={`${websiteurl}/promotion`}>Offers</Link>
              </li>

            </ul>
          )}
        </div>
        <div className={styles.hamburger_menu3}>
          <div className={styles.subscribe_menu3}>
            <NewsletterModal />
          </div>

          <ul>
            <li key="facebook">
              <a onClick={() => HamburgerOptionClick('N/A', 'N/A', 'N/A', 'fb', eventCommonObj)} href="https://www.facebook.com/KamaAyurveda" target="_blank">
                <img
                  loading="lazy"
                  src={facebook}
                  alt="facebook-icon"
                  width={30} height={24}
                />
              </a>
            </li>
            <li key="twitter">
              <a
                onClick={() => HamburgerOptionClick('N/A', 'N/A', 'N/A', 'twitter', eventCommonObj)}
                href="https://twitter.com/kamaayurveda?lang=en"
                target="_blank"
              >
                <img
                  loading="lazy"
                  src={twitter}
                  alt="twitter-icon"
                  width={30} height={24}
                />
              </a>
            </li>
            <li key="insta">
              <a onClick={() => HamburgerOptionClick('N/A', 'N/A', 'N/A', 'instagram', eventCommonObj)} href="https://www.instagram.com/kamaayurvedaindia/" target="_blank">
                <img
                  loading="lazy"
                  src={insta}
                  alt="insta-icon"
                  width={30} height={24}
                />
              </a>
            </li>
            <li key="youtube">
              <a onClick={() => HamburgerOptionClick('N/A', 'N/A', 'N/A', 'youtube', eventCommonObj)} href="https://www.youtube.com/channel/UCMbwFLJf2buLbHz-R2H_-aw" target="_blank">
                <img
                  loading="lazy"
                  src={youtube}
                  alt="youtube-icon"
                  width={34} height={24}
                />
              </a>
            </li>
          </ul>
          <p>©2025 Kama Ayurveda.</p>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <FormLoader show={showLoader} />
      <div className={styles["modal_international"]} style={{ display: showCurrencyModal ? "flex" : "none" }}>
        <div className={styles["close_btn_before"]} onClick={handleShowCurrencyModal}>
          <button type="button" className={styles["close_btn"]} onClick={handleShowCurrencyModal}>
            <img loading="lazy" src={cancel} width={14} className="d-block" alt="cancel icon" /></button>
          <div className={styles["modal_body"]}>
            {(storeType == "international" ? <p>You are on Kama Ayurveda International Website. Switch to our India
              Website for India shipping.</p> : <p>You are on Kama Ayurveda Website. Switch to our International
                Website for International shipping.</p>)}
            <a onClick={() => {
              changeUserCurrecnyViaPrompt((storeType == "default") ? "international" : "default");
            }}>Switch To {(storeType == "international") ? "India" : "International"} Website</a>
          </div>
        </div>
      </div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          {isMobileDevice ? (
            <img
              loading="lazy"
              onClick={(e) => {
                TopNavigationClick('hamburger_icon', eventCommonObj)
                toggleDrawer(anchor, true)(e);
              }}
              src={hamburger_icon}
              alt="hamburger-icon"
              width="30px"
              height="30px"
            />
          ) : (
            <img
              loading="lazy"
              onClick={(e) => {
                TopNavigationClick('hamburger_icon', eventCommonObj)
                toggleDrawer(anchor, true)(e);
              }}
              src={hamburger_icon}
              alt="hamburger-icon"
              width="40px"
              height="40px"
            />
          )}
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}

    </div>
  );
}
