import "../styles/bootstrap.min.css";
import "../styles/globals.css";
import '../styles/style.css';
import '../styles/account-edit.css';
import '../styles/account.css'
import dynamic from "next/dynamic";
import Script from 'next/script'
import Head from 'next/head';

import MarketingPopup from "/components/MarketingPopup/MarketingPopup";
import MobileVerifyModal from "/components/MobileVerifyModal";
import DesktopTopHeader from "../components/layout/navbar-desktop";
import MobileTopHeader from "../components/layout/top-header";
const DesktopFooter = dynamic(() => import("../components/layout/footer-desktop"));
const MobileFooter = dynamic(() => import("../components/layout/footer"));
const CheckoutHeader = dynamic(() => import("../components/layout/checkout-header"));
const CheckoutFooter = dynamic(() => import("../components/layout/checkout-footer"));
const Spinthewheel = dynamic(() => import("../components/layout/spinthewheel"));
// const EyeSerumMarketingPopup = dynamic(() => import("/components/ui/EyeSerumMarketingPopup"));
import ResponseMessageBox from '../components/ResponseMessageBox/ResponseMessageBox'
import App from "../components/App";

import { AuthProvider } from "../lib/auth.js";
import { ApolloProvider } from "@apollo/client";
import { useApollo } from "../lib/apollo-client";
import { CookiesProvider } from "react-cookie";
import { useRouter } from 'next/router';
import { Provider, signOut } from 'next-auth/client'
import { Provider as StoreProvider } from 'react-redux'
import { useStore } from '../components/store/store'
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import { logoutUser } from "../lib/magento-api"
import { setReduxSignout } from "../lib/sessionHandler"
import { useIsMobile } from "/lib/hooks/useIsMobile";
import { Optimiseevent, pageview } from "/lib/ga";
import { loadCSS } from "/lib/hooks/loadCSS";
import { getConfigDataSync } from "/lib/services/helpers/cache/getConfigData";

function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const outerTheme = createTheme({
  palette: {
    primary: {
      main: '#C4601D',
    },
  },
  overrides: {
    MuiInput: {
      root: {
        '&::before': {
          borderBottom: 'none !important',
        }
      },
    },
  },
});

const noFoot = ['/store-spin-the-wheel', '/know-your-dosha-quiz', '/teaser'];
const removeParentClass = ['/store-spin-the-wheel', '/know-your-dosha-quiz','/teaser']

function MyApp({ Component, pageProps }) {
  const isMobileType = useIsMobile(pageProps?.isSsrMobile)
  const store = useStore(pageProps.initialReduxState)
  const apolloClient = useApollo(pageProps.initialApolloState);
  const [storecookie, setStoreCookie] = useCookies(["store"]);
  const [showFooter, setShowFooter] = useState(false);
  const router = useRouter();
  const { asPath, pathname } = router;
  const isCartPage = isMobileType ? (pathname == "/checkout" || pathname.includes('cart') || pathname.includes('payment') || pathname.includes('checkoutlogin')) : (pathname == "/checkout" || pathname.includes('payment')) ? true : false
  const noHead = isMobileType ? ['/review/[pid]', '/askkama/[pid]', '/store-spin-the-wheel', '/teaser'] : ['/store-spin-the-wheel', '/teaser']
  const storeType = typeof storecookie?.store == "undefined" || storecookie?.store == "default" ? "default" : "international";
  const storeData = typeof window != "undefined" ? window["__store_data__"] : getConfigDataSync(storeType)

  
  let blogUrl = asPath
  if (storecookie.store == "international") {
    blogUrl = asPath.replace("/international", "")
  }

  function changeStore(store) {
    signOut({ redirect: false })
    setStoreCookie("store", store, {
      path: "/",
      maxAge: 30 * 24 * 60 * 60,
      sameSite: true,
    });
    const response = async () => {
      try {
        await logoutUser();
        setReduxSignout()
        if ((typeof window !== 'undefined')) {
          if (process.browser) {
            location.reload();
          }
        }
      } catch (error) {
        console.log('error in changing store', error);
      }
    };
    response();
  }

  useEffect(() => {
    const handleRouteChange = (url) => {
      Optimiseevent(url)
      pageview(url)
    }
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on('routeChangeComplete', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  useEffect(() => {
    if (asPath.includes('utm_source')) {
      const utm_source = getParameterByName('utm_source');
      if (utm_source) {
        setStoreCookie("utm_source", utm_source, {
          path: "/",
          maxAge: 30 * 24 * 60 * 60,
          sameSite: true,
        });
      }
    }
    if (asPath.includes('utm_medium')) {
      const utm_medium = getParameterByName('utm_medium');
      if (utm_medium) {
        setStoreCookie("utm_medium", utm_medium, {
          path: "/",
          maxAge: 30 * 24 * 60 * 60,
          sameSite: true,
        });
      }
    }
    if (asPath.includes('/international')) {
      if (storecookie.store != "international") {
        changeStore('international')
      }
    }
    if (!asPath.includes('/international')) {
      if (storecookie.store == "international") {
        changeStore('default')
      }
    }
    if (typeof storecookie.store == "undefined" && (!asPath.includes('/international'))) {
      setStoreCookie("store", 'default', {
        path: "/",
        maxAge: 30 * 24 * 60 * 60,
        sameSite: true,
      });
    }
    // if (!localStorage.getItem('ayurvedicCount')) {
    //   localStorage.setItem('ayurvedicCount', process.env.NEXT_PUBLIC_CHRISTMAS_MODAL_RESTRICTION);
    // }

    if (!storeData?.storeConfig?.enable_website_spinthewheel) {
      localStorage.removeItem('claimed_coupon')
    }

    loadCSS("/css/minicart.css").then((res) => {
      // setShowFooter(true)
    }).catch((err) => {
      console.log(err)
    })
  }, []);

  useEffect(() => {
    const scrollHandler = () => {
      setShowFooter(window.pageYOffset > 400);
      // window.pageYOffset > 400 ? setShowFooter(true) : setShowFooter(false);
    };
    window.addEventListener('scroll', scrollHandler);
    scrollHandler();

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    }
  }, []);

  return (
    <ThemeProvider theme={outerTheme}>
      <Provider options={{ clientMaxAge: 0, keepAlive: 60 }} session={pageProps.session}>
        <AuthProvider>
          <StoreProvider store={store}>
            <ApolloProvider client={apolloClient}>
              <Head>
                <meta name="viewport" content="user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, width=device-width, height=device-height, target-densitydpi=device-dpi" />
                <meta httpEquiv="X-UA-Compatible" content="IE=9; IE=8; IE=7; IE=EDGE" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta charSet="utf-8" />
                <meta name="format-detection" content="telephone=no" />
                {
                  (!pathname.includes('_url-resolver') && !pathname.includes('blog')) ? (
                    (asPath.includes('/international')) ? <>
                      <link rel="alternate" hrefLang="en-us" href={`${process.env.NEXTAUTH_URL}${asPath}`} />
                      <link rel="alternate" hrefLang="en-ca" href={`${process.env.NEXTAUTH_URL}${asPath}`} />
                      <link rel="alternate" hrefLang="en-au" href={`${process.env.NEXTAUTH_URL}${asPath}`} />
                      <link rel="alternate" hrefLang="en-ae" href={`${process.env.NEXTAUTH_URL}${asPath}`} />
                      <link rel="alternate" hrefLang="en-sg" href={`${process.env.NEXTAUTH_URL}${asPath}`} />
                      <link rel="alternate" hrefLang="en-in" href={`${process.env.NEXTAUTH_URL}${asPath.replace("/international", "")}`} />
                      <link rel="alternate" hrefLang="x-default" href={`${process.env.NEXTAUTH_URL}${asPath}`} />
                    </> : <>
                      <link rel="alternate" hrefLang="en-in" href={`${process.env.NEXTAUTH_URL}${asPath}`} />
                      <link rel="alternate" hrefLang="hi-in" href={`${process.env.NEXTAUTH_URL}${asPath}`} />
                      <link rel="alternate" hrefLang="x-default" href={`${process.env.NEXTAUTH_URL}${asPath}`} />
                      <link rel="alternate" hrefLang="en-gb" href={`${process.env.NEXTINTERNATION_URL}/uk/en_GB`} />
                    </>
                  ) : null
                }
                {
                  (pathname.includes('_url-resolver')) ? '' : <link rel="canonical" href={`${process.env.NEXTAUTH_URL}${asPath}`} />
                }

                <link rel="icon" type="image/x-icon" href="https://static.kamaayurveda.in/pub/media/favicon/stores/1/favicon_1.png" />
                <link rel="shortcut icon" type="image/x-icon" href="https://static.kamaayurveda.in/pub/media/favicon/stores/1/favicon_1.png" />
                <meta name="google-site-verification" content="DitowkksSr3qhGMbkmZwGTYYXyGV1Jz5C2rdYs0xTgs" />
                <meta name="google-site-verification" content="google3f082ab168e42590.html" />
                <meta property="fb:app_id" content="1103643826320483" />
                <meta property="og:type" content="website" />
                {
                  (asPath.includes('/blog/')) ? <meta property="og:url" content={process.env.NEXTAUTH_URL + blogUrl} /> : <meta property="og:url" content={process.env.NEXTAUTH_URL + asPath} />
                }
                <meta property="og:site_name" content="Kama Ayurveda" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:site" content="@kamaayurveda" />
                <meta name="twitter:creator" content="@kamaayurveda" />
                <link rel="manifest" href="/manifest.json" />
                <meta name="theme-color" content="#c4601d" />
                <link rel="apple-touch-icon" href="/short-icon-color-96.png" />
                <meta name="apple-mobile-web-app-status-bar" content="#c4601d" />
                <link rel="preconnect" href="https://analytics.google.com" />
                <link href="http://track.omguk.com" />
                <meta name="facebook-domain-verification" content="dnuudc665ga48klw1wrsd6ydjyn92b" />

              </Head>

              {/* rtb-global */}
              <Script
                async
                strategy="lazyOnload" src="https://rtb-global.com/rtbv2.js" />

              <Script
                async
                strategy="afterInteractive"
                src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_PROPERTY_ID}`}
              />

              <Script
                async
                strategy="afterInteractive"
                id="show-gtm1"
                dangerouslySetInnerHTML={{
                  __html: `
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_PROPERTY_ID}', {
                      page_path: window.location.pathname,
                    });
                  `,
                }}
              />

              {
                (process.env.DEBUG == true || process.env.DEBUG == 'true') ? '' : (
                  <Script strategy="afterInteractive" id="show-gtm2"
                    dangerouslySetInnerHTML={{
                      __html: `
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-TTXNJG');`
                    }} />
                )
              }

              {/* OPTIMISE */}
              <Script strategy="lazyOnload" id="omguk"
                dangerouslySetInnerHTML={{
                  __html: `OMID=2318088;OPID=52364;ORef=escape(window.parent.location.href);!function(){var a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src="//track.omguk.com/e/qs/?action=Content&MID=" + OMID + "&PID=" + OPID + "&ref="+ ORef;var b=document.getElementsByTagName("body")[0];if(b)b.appendChild(a,b);else{var b=document.getElementsByTagName("script")[0];b.parentNode.insertBefore(a,b)}}();`,
                }}
              />

              {/* Trackier pixel */}
              {/* <Script strategy="afterInteractive" src='https://static-cdn.trackier.com/js/trackier-web-sdk.js'
                onLoad={() => {
                  window.TrackierWebSDK.init();
                }}
              /> */}

              {/* Trackier new Pixel */}
              {/* <Script strategy="afterInteractive" src='https://static-cdn.trackier.com/rtg/650d71f814ec5a69c94e2a96.js' />

              <Script strategy="afterInteractive" id="showTrackierPixel"
                dangerouslySetInnerHTML={{
                  __html: `
                      (function(){ var s = document.createElement("script"); s.async = true; s.src = (document.location.protocol == "https:" ? "https:" : "http:") + "//webtrafficsource.com/track/code.js"; var a = document.getElementsByTagName("script")[0]; a.parentNode.insertBefore(s, a); })();`
                }} /> */}

              <App>
                {
                  (isCartPage) ? <CheckoutHeader cart={pathname.includes('cart')} /> : (noHead.includes(pathname) ? null : isMobileType ? <MobileTopHeader /> : <DesktopTopHeader />)
                }

                {
                  (pathname.includes('_url-resolver') || pathname == "/") && storecookie?.store == 'default' && storeData?.storeConfig?.enable_website_spinthewheel && <Spinthewheel isMobileType={isMobileType} />
                }

                <div className={removeParentClass.includes(pathname) ? '' : 'page_content'}>
                  <CookiesProvider>
                    <MobileVerifyModal />
                    {/* <EyeSerumMarketingPopup /> */}
                   {pathname === '/know-your-dosha-quiz' ? null : <MarketingPopup />}
                    <Component {...pageProps} />
                    <ResponseMessageBox />
                  </CookiesProvider>
                </div>
                {
                  (isCartPage) ? <CheckoutFooter cart={pathname.includes('cart')} /> : (noFoot.includes(pathname) ? null : isMobileType ? (showFooter ? <MobileFooter isMobileType={isMobileType} /> : null) : (showFooter ? <DesktopFooter isMobileType={isMobileType} /> : null))
                }
              </App>
            </ApolloProvider >
          </StoreProvider >
        </AuthProvider >
      </Provider >
    </ThemeProvider >
  );

}

export default MyApp;
