import Link from "next/link";
import classes from "./top-header.module.css";
import ToggleMenu from "./toggle-menu";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import FormLoader from "/components/ui/FormLoader"

import { ProfileOptinsClick, TopNavigationClick, TopOfferBar } from "/lib/ga";
import { useTopHeader } from "/lib/hooks/Navbar/useTopHeader";

import new_logo from '~/public/images/logonew.inline.svg';
import cart_icon from '~/public/images/newCartIcon.inline.svg';
import user_icon_desk from '~/public/images/newUserIcon.inline.svg';
import login_icon from '~/public/images/loginIcon.inline.svg';
import register_icon from '~/public/images/registerIcon.inline.svg';
import bag_icon from '~/public/images/bagIcon.inline.svg';
import account_icon2 from '~/public/images/accountNew.inline.svg';
import wishlist2 from '~/public/images/wishlistNewMenu.inline.svg';
import search_icon from '~/public/images/search-icon.inline.svg';
import track_icon2 from '~/public/images/trackOrder.inline.svg';

function TopHeader() {
  const {
    showLoader,
    responsive,
    homeTicker,
    eventCommonObj,
    stickClass,
    navigationSSR,
    StaticMenu,
    session,
    prefixUrl,
    count,
    anchorRef,
    accountLink,
    storeType,
    salesOrderLink,
    wishlistLink,
    storecookie,
    websiteurl,
    open,
    SignoutUser,
    handleToggle,
    handleClose
  } = useTopHeader()

  return <>
    <FormLoader show={showLoader} />
    <div className={classes.promo_add}>
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlaySpeed={3000}
        autoPlay={true}
        keyBoardControl={true}
        partialVisible={true}
        removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
        additionalTransfrom={0}
      >
        {
          homeTicker?.map(function (item, index) {
            // Mobile top_most_offer_bar ga_event
            const offerInfo = {
              event: 'offer_ribbon_bar',
              offer_name: item.text || '',
            };

            return (
              <p onClick={() => TopOfferBar(offerInfo, eventCommonObj)} key={index}><a href={item.link}>{item.text}</a> </p>

            )
          })
        }
      </Carousel>
    </div>
    <header className={classes.header + " " + (stickClass ? classes[stickClass] : '')}>
      <div className="container">
        <div className="row">
          <div className={`col-4 ${classes.menuIcon}`}>
            <div onClick={() => TopNavigationClick('hamburger_menu', eventCommonObj)} className={classes.menutoggle}>
              <ToggleMenu menudata={navigationSSR} staticmenu={StaticMenu} session={session} />
            </div>
            <div className={classes.menusearch}>
              <ul>
                <li onClick={() => TopNavigationClick('search_icon', eventCommonObj)} key="search" className={classes.searchBox}>
                  <Link prefetch={false} href={`${prefixUrl}/catalogsearch/result`} className="active">

                    <img
                      loading='lazy'
                      src={search_icon}
                      alt="search icon"
                      width="30"
                      height="30"
                    />

                  </Link>
                </li></ul>
            </div>
          </div>
          <div onClick={() => TopNavigationClick('logo_name', eventCommonObj)} className={`col-4 col-md-4 ${classes.logoMain}`}>
            <Link href={(prefixUrl != '') ? prefixUrl : "/"} className="active">

              <img
                loading='lazy'
                src={new_logo}
                title="kama Ayurveda Logo"
                alt="kama Ayurveda Logo"
                width="100"
                height="50"
              />

            </Link>
          </div>
          <div className={`col-4 ${classes.rightMenu}`}>
            <ul>
              <li onClick={() => TopNavigationClick('view_cart', eventCommonObj)} key="logo" className={classes.bagCart}>
                <Link prefetch={false} href={`${prefixUrl}/checkout/cart`} className="active" title="Cart">

                  <img
                    loading='lazy'
                    src={cart_icon}
                    alt="mini cart"
                    width="30"
                    height="30"
                  />
                  <span>
                    {count ? count : 0}
                  </span>

                </Link>
              </li>
              <li
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
              >
                <a>
                  <img
                    loading='lazy'
                    src={user_icon_desk}
                    alt="user icon"
                    width={30}
                    height={30}
                  />
                </a>
              </li>
              <Popper className={classes["dropdown-ul-wrap"]} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <ul onClick={handleToggle} className={classes["dropdown-ul"]}>
                          {(session?.accessToken) ? '' : (
                            <><li onClick={() => ProfileOptinsClick('sign_in', eventCommonObj)}><Link prefetch={false} href={accountLink}><span><img loading='lazy' src={login_icon} alt="login icon" width={19} height={19} /></span> Sign in</Link></li>
                              <li onClick={() => ProfileOptinsClick('register', eventCommonObj)}><Link prefetch={false} href={`${websiteurl}/customer/account/create`}><span><img loading='lazy' src={register_icon} alt="register icon" width={19} height={19} /></span> Register</Link></li></>
                          )}
                          <li onClick={() => ProfileOptinsClick('my_cart', eventCommonObj)}><Link prefetch={false} href={`${websiteurl}/checkout/cart`}><span><img loading='lazy' src={bag_icon} alt="bag icon" width={19} height={19} /></span> My Cart</Link></li>
                          <li onClick={() => ProfileOptinsClick('my_account', eventCommonObj)}><Link prefetch={false} href={accountLink}><span><img loading='lazy' src={account_icon2} alt="account icon" width={19} height={19} /></span> My Account</Link></li>
                          {(session?.accessToken) ?
                            (<><li onClick={() => ProfileOptinsClick('my_wishlist', eventCommonObj)}><Link prefetch={false} href={`${websiteurl}/sales/order/history`}><span><img loading='lazy' src={bag_icon} alt="bag icon" width={19} height={19} /></span> My Orders</Link></li><li><Link prefetch={false} href={wishlistLink}><span><img loading='lazy' src={wishlist2} alt="wishlist icon" width={19} height={19} /></span> My WishList</Link></li></>
                            )
                            : (
                              ''
                            )}
                          {storeType != 'international' && <li onClick={() => ProfileOptinsClick('track_your_order', eventCommonObj)}><Link prefetch={false} href={salesOrderLink}><span><img loading='lazy' src={track_icon2} alt="track icon" width={19} height={19} /></span> Track your order</Link></li>}
                          {session?.accessToken && <li><a onClick={(e) => {
                            ProfileOptinsClick('sign_out', eventCommonObj)
                            SignoutUser(storecookie, e);
                          }}><span><img loading='lazy' src={login_icon} alt="sign out icon" width={19} height={19} /></span> Sign out</a></li>}

                        </ul>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )
                }
              </Popper>
            </ul>
          </div>
        </div>
      </div>
    </header>
    <div className={classes.header_margin_top}></div>
  </>;
}

export default TopHeader;
